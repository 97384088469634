var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-grid"},[_c('v-container',{staticClass:"category-title-container"},[_c('category-title',{attrs:{"category":_vm.category}})],1),_vm._l((_vm.$ebsn.meta(
      _vm.category,
      'template_model.GRID_CONFIG.rows'
    )),function(row,rowIndex){return _c('div',{key:rowIndex,style:(`${row.style}; ${
        row.backgroundColor && row.backgroundColor != ''
          ? 'backgroundColor: ' + row.backgroundColor + ';'
          : ''
      }`)},[(row.container)?_c('v-container',{staticClass:"container-row"},[_c('v-row',{class:`row-number-${rowIndex}`},[(row.heights)?_c('ebsn-style',{attrs:{"target":{
            metaData: {
              css: _vm.templateHelper.calcHeightStyle(
                row.heights,
                `.row-number-${rowIndex}`
              )
            }
          },"path":"css"}}):_vm._e(),_vm._l((row.cols),function(column,columnIndex){return _c('v-col',{key:columnIndex,class:`col-number-${columnIndex}`,attrs:{"cols":column.xs || 12,"sm":column.sm,"md":column.md,"lg":column.lg,"xl":column.xl}},[(typeof column.position === 'object')?_vm._l((column.position),function(position,index){return _c('category-block',{key:index,staticClass:"category-block h-100 multiple-col",class:index < column.position.length
                  ? `col-num-${index}`
                  : `col-num-${index}`,attrs:{"position":position,"target":_vm.category,"container":false}})}):_c('category-block',{staticClass:"category-block h-100",attrs:{"position":column.position,"target":_vm.category,"container":false}})],2)})],2)],1):_c('v-row',{key:rowIndex,class:`row-number-${rowIndex}`},[(row.heights)?_c('ebsn-style',{attrs:{"target":{
          metaData: {
            css: _vm.templateHelper.calcHeightStyle(
              row.heights,
              `.row-number-${rowIndex}`
            )
          }
        },"path":"css"}}):_vm._e(),_vm._l((row.cols),function(column,columnIndex){return _c('v-col',{key:columnIndex,class:`col-number-${columnIndex}`,attrs:{"cols":column.xs || 12,"sm":column.sm,"md":column.md,"lg":column.lg,"xl":column.xl}},[(typeof column.position === 'object')?_vm._l((column.position),function(position,index){return _c('category-block',{key:index,class:`category-block h-100 col-num-${index}`,attrs:{"position":position,"target":_vm.category,"container":false}})}):_c('category-block',{staticClass:"category-block h-100",attrs:{"position":column.position,"target":_vm.category,"container":false}})],2)})],2)],1)}),_c('ebsn-meta',{staticClass:"category-block-footer",attrs:{"target":_vm.category,"path":"category_info.FOOTER_TEXT","tag":"div"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }